body {
    margin: 0;
    padding: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background: #f2f2f2;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.autocomplete_suggestions {
    position: absolute;
    background: white;
    z-index: 1000;
    border: 1px solid #999;
    border-top-width: 0;
    list-style: none;
    margin-top: 0;
    max-height: 500px;
    overflow-y: auto;
    padding-left: 0;
    width: calc(600px + 1rem);
}

.autocomplete_suggestions li:hover {
    background-color: dodgerblue;
    color: white;
    cursor: pointer;
    font-weight: 900;
}

.marker22 {
    /*content: "";*/
    /*display: block;*/
    position: absolute;
    left: -13px;
    top: -25px;
    width: 25px;
    height: 25px;
}

.marker-pickup {
    background: url(https://zum-admin-dev-2.firebaseapp.com/assets/icons/marker-icon-green.svg) center no-repeat;
}

.marker-dropoff {
    background: url(https://zum-admin-dev-2.firebaseapp.com/assets/icons/marker-icon-orange.svg) center no-repeat;
}

.btn-group .btn-outline-secondary {
    background: #FFF;
}

.m-ride__distance:before {
    content: "";
    width: 12px;
    height: 14px;
    display: inline-block;
    background-image: url(https://zum-admin-dev-2.firebaseapp.com/assets/icons/distance-icon.svg);
    background-position: center center;
    background-repeat: no-repeat;
    background-size: 100% auto;
    margin-right: 5px;
    position: relative;
    top: 2px;
    margin-left: 2px;
}

.m-ride__time-value:before {
    content: "";
    width: 12px;
    height: 14px;
    display: inline-block;
    background-image: url(https://zum-admin-dev-2.firebaseapp.com/assets/icons/time-icon.svg);
    background-position: center center;
    background-repeat: no-repeat;
    background-size: 100% auto;
    margin-right: 5px;
    position: relative;
    top: 2px;
    margin-left: 2px;
}

.m-ride__date:before {
    content: "";
    width: 18px;
    height: 18px;
    display: inline-block;
    background-image: url(https://zum-admin-dev-2.firebaseapp.com/assets/icons/date-icon.svg);
    background-position: center center;
    background-repeat: no-repeat;
    background-size: 100% auto;
    margin-right: 6px;
    position: relative;
    top: 3px;
    left: -1px;
}

.m-ride__event-label {
    color: #b3b3b3;
    /*margin-bottom: 7px;*/
    font-size: 12px;
}

.m-ride__event.pickup .m-ride__event-time:before {
    content: "";
    width: 14px;
    height: 16px;
    display: inline-block;
    background-image: url(https://zum-admin-dev-2.firebaseapp.com/assets/icons/time-icon-green.svg);
    background-position: center center;
    background-repeat: no-repeat;
    background-size: 100% auto;
    margin-right: 5px;
}

.m-ride__event.pickup .m-ride__event-address:before {
    content: "";
    width: 13px;
    height: 16px;
    display: inline-block;
    background-image: url(https://zum-admin-dev-2.firebaseapp.com/assets/icons/marker-pickup.svg);
    background-position: center center;
    background-repeat: no-repeat;
    background-size: 100% auto;
    margin-right: 5px;
}

.m-ride__event.dropoff .m-ride__event-time:before {
    content: "";
    width: 14px;
    height: 16px;
    display: inline-block;
    background-image: url(https://zum-admin-dev-2.firebaseapp.com/assets/icons/time-icon-orange.svg);
    background-position: center center;
    background-repeat: no-repeat;
    background-size: 100% auto;
    margin-right: 5px;
}

.m-ride__event.dropoff .m-ride__event-address:before {
    content: "";
    width: 13px;
    height: 16px;
    display: inline-block;
    background-image: url(https://zum-admin-dev-2.firebaseapp.com/assets/icons/marker-dropoff.svg);
    background-position: center center;
    background-repeat: no-repeat;
    background-size: 100% auto;
    margin-right: 5px;
}

.m-ride__event-address:before {
    position: absolute;
    top: 3px;
    left: 0;
}

.m-ride__event-time:before {
    position: absolute;
    top: 3px;
    left: 0;
}

.btn-outline-secondary:hover {
    color: #456;
}

.m-ride__children:before {
    content: "";
    width: 20px;
    height: 20px;
    display: inline-block;
    background-image: url(https://zum-admin-dev-2.firebaseapp.com/assets/icons/children-icon.svg);
    background-position: center center;
    background-repeat: no-repeat;
    background-size: 100% auto;
    margin-right: 5px;
    position: absolute;
    top: 0;
    left: -3px;
}

.m-ride__owner:before {
    content: "";
    width: 14px;
    height: 14px;
    display: inline-block;
    background-image: url(https://zum-admin-dev-2.firebaseapp.com/assets/icons/person.svg);
    background-position: center center;
    background-repeat: no-repeat;
    background-size: 100% auto;
    margin-right: 20px;
    position: absolute;
    top: 0;
    left: 0;
}

.m-ride__customer:before {
    content: "";
    width: 14px;
    height: 14px;
    display: inline-block;
    background-image: url(https://zum-admin-dev-2.firebaseapp.com/assets/icons/person-icon-grey.svg);
    background-position: center center;
    background-repeat: no-repeat;
    background-size: 100% auto;
    margin-right: 20px;
    position: absolute;
    top: 0;
    left: 0;
}

.m-ride__driver-info:before {
    content: "";
    width: 18px;
    height: 16px;
    display: inline-block;
    background-image: url(https://zum-admin-dev-2.firebaseapp.com/assets/icons/driver-car-icon.svg);
    background-position: center center;
    background-repeat: no-repeat;
    background-size: 100% auto;
    margin-right: 5px;
    position: absolute;
    top: 3px;
    left: 0;
}
