// Override default variables before the import
$body-bg: white;
$list-group-item-padding-y: 0.5rem;
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins/hover';
$badge-border-width: $input-border-width;

@mixin badge-outline-variant($color, $color-hover: color-yiq($color), $active-background: $color, $active-border: $color) {
  color: $color;
  border: $border-width solid $color;

  &:focus,
  &.focus {
    outline: 0;
    box-shadow: 0 0 0 $badge-focus-width rgba($color, .5);
  }
}

@each $color, $value in $theme-colors {
  .badge-outline-#{$color} {
    @include badge-outline-variant($value);
  }
}

// Import Bootstrap and its default variables
@import '~bootstrap/scss/bootstrap';
